import { FetchCredentialingWorkflowsAPIResponse } from '#/api/credentialing';
import { PREFERRED_TIMEZONE } from '#/Utils/Constants';
import {
  CredentialingTimelineFromApi,
  CredentialingWorkflowDetails,
  CredentialingWorkflowListState,
  CredentialingTimeline,
  CredTimelineStep,
  CredWorkflowStep,
  FetchCredentialingWorkflowByIdResponse,
} from '../types';
import {
  firestoreDateInUtc,
  apiResponseDateToDate,
  convertToLocaleDate,
} from '../Utils/date';

export enum CREDENTIALING_TRANSFORMER_SOURCES {
  FACILITY_CREDENTIALING_TRANSFORMER,
  PROVIDER_CREDENTIALING_TRANSFORMER,
}

export const transformStepForCredTimeline = (
  step: CredWorkflowStep,
  source?: CREDENTIALING_TRANSFORMER_SOURCES,
): CredTimelineStep => {
  let status: 'in-progress' | 'completed' | 'pending' = 'pending';

  if (step.enteredAt && !step.exitedAt) {
    status = 'in-progress';
  } else if (step.exitedAt) {
    status = 'completed';
  }
  const isFacilityCred =
    source ===
      CREDENTIALING_TRANSFORMER_SOURCES.FACILITY_CREDENTIALING_TRANSFORMER ||
    CREDENTIALING_TRANSFORMER_SOURCES.PROVIDER_CREDENTIALING_TRANSFORMER;
  return {
    id: step.id,
    name: step.title,
    seqNo: step.seqNo,
    status,
    createdAt: isFacilityCred
      ? convertToLocaleDate(step.createdAt, PREFERRED_TIMEZONE)
      : apiResponseDateToDate(step.createdAt),
    enteredAt: isFacilityCred
      ? convertToLocaleDate(step.enteredAt, PREFERRED_TIMEZONE)
      : apiResponseDateToDate(step.enteredAt),
    exitedAt: isFacilityCred
      ? convertToLocaleDate(step.exitedAt, PREFERRED_TIMEZONE)
      : apiResponseDateToDate(step.exitedAt),
    prevCreatedDate: apiResponseDateToDate(step.prevCreatedDate),
    updatedAt: apiResponseDateToDate(step.updatedAt),
    updatedByName: step.updatedByName,
  };
};

export const transformStepsForCredTimeline = (
  steps: CredWorkflowStep[],
  source?: CREDENTIALING_TRANSFORMER_SOURCES,
): CredTimelineStep[] => {
  const updatedSteps = (steps as CredWorkflowStep[])
    .map((step) => transformStepForCredTimeline(step, source))
    // @ts-ignore
    .sort((a, b) => (a.seqNo < b.seqNo ? -1 : 1));

  return updatedSteps;
};

export const transformCredentialingWorkflowByIdResponseToDetails = (
  data: FetchCredentialingWorkflowByIdResponse,
): CredentialingWorkflowDetails => {
  const source =
    CREDENTIALING_TRANSFORMER_SOURCES.PROVIDER_CREDENTIALING_TRANSFORMER;
  const notes = !data.notes
    ? []
    : data.notes.map((note) => {
        return {
          ...note,
          createdAt: apiResponseDateToDate(note?.createdAt),
          updatedAt: apiResponseDateToDate(note?.updatedAt),
        };
      });
  return {
    workflowId: data.id,
    providerId: data.providerId,
    organizationId: data.organizationId,
    version: data.version,
    isCompleted: data.isCompleted as boolean,
    onStep: transformStepForCredTimeline(data.onStep, source),
    psvFile: data.psvFile,
    psvFileSignedUrl: data.psvFileSignedUrl,
    psvGenerated: data.psvGenerated,
    psvChecklist: data.psvChecklist,
    rulesEngineResults: data.rulesEngineResults,
    credCommDetails: data.credCommDetails,
    credentialingCycle: data.credentialingCycle,
    outreachReasons: data.outreachReasons ? data.outreachReasons : [],
    steps: transformStepsForCredTimeline(
      data.steps as CredWorkflowStep[],
      source,
    ),
    totalOutreachAttempts: data.totalOutreachAttempts,
    lastOutreachAttempt: data.lastOutreachAttempt,
    assignment: data.assignment,
    psvFileGenerationErrors: data.psvFileGenerationErrors,
    notes,
  };
};

export const transformCredentialingWorkflowListResponse = (
  response: FetchCredentialingWorkflowsAPIResponse,
): CredentialingWorkflowListState => {
  return {
    count: response.count,
    data: response.data
      ? response.data.map((item) => ({
          id: item.id,
          assignment: item.assignment,
          providerId: item.providerId,
          organizationId: item.organizationId,
          firstName: item?.providerFirstName,
          lastName: item?.providerLastName,
          npi: item.provider?.npi,
          outreachReasons: item?.outreachReasons ? item.outreachReasons : [],
          providerType: item.provider?.providerType,
          providerFileType: item?.provider?.fileType,
          providerStates: item?.providerStates
            ? item?.providerStates?.sort().join(', ')
            : '',
          assignedStates: item?.provider?.assignedStates?.sort() || [],
          providerStatus: item?.provider?.credentialingStatus,
          onStep: item.onStep?.title,
          credCommDetails: item.credCommDetails,
          createdAt: apiResponseDateToDate(item?.createdAt)!,
          hasFlags: item?.provider?.isFlagged,
          credentialingCycle: item.credentialingCycle,
        }))
      : [],
  };
};

export const transformCredentialingWorkflowTimeline = (
  credentialingWorkflowTimelineApiResponse: CredentialingTimelineFromApi,
): CredentialingTimeline => {
  return {
    medicalDirectorName:
      credentialingWorkflowTimelineApiResponse?.medicalDirectorName,
    receivedForCredentialingDate: convertToLocaleDate(
      credentialingWorkflowTimelineApiResponse?.receivedForCredentialingDate,
    ),
    attestationDate: convertToLocaleDate(
      credentialingWorkflowTimelineApiResponse?.attestationDate,
    ),
    lastCredentialedDate: convertToLocaleDate(
      credentialingWorkflowTimelineApiResponse?.lastCredentialedDate,
    ),
    psvCompleteDate: convertToLocaleDate(
      credentialingWorkflowTimelineApiResponse?.psvCompleteDate,
    ),
    credentialingDecisionDate: convertToLocaleDate(
      credentialingWorkflowTimelineApiResponse?.credentialingDecisionDate,
    ),
    nextCredentialingDate: convertToLocaleDate(
      credentialingWorkflowTimelineApiResponse?.nextCredentialingDate,
    ),
    verificationDate: convertToLocaleDate(
      credentialingWorkflowTimelineApiResponse?.verificationDate,
    ),
    verifierId: credentialingWorkflowTimelineApiResponse?.verifierId,
    id: credentialingWorkflowTimelineApiResponse?.id,
    createdAt: convertToLocaleDate(
      credentialingWorkflowTimelineApiResponse?.createdAt,
    ),
    createdByName: credentialingWorkflowTimelineApiResponse?.createdByName,
    updatedByName: credentialingWorkflowTimelineApiResponse?.updatedByName,
    initialCredentialingDate: convertToLocaleDate(
      credentialingWorkflowTimelineApiResponse?.initialCredentialingDate,
    ),
  };
};
